import React from 'react';
import './Loader.scss';

const Loader = () => {
	return (
		<div class="loader-wrapper">
			<div class="lds-dual-ring"></div>
		</div>
	);
};

export default Loader;