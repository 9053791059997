import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export const Seo = ({ lang = `en`, meta = [], title = "Dreamclean" }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            robots
            googlebot
            bingbot
            description
            author
            image
          }
        }
      }
    `
  );

  const metaDescription = site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: site.siteMetadata.robots,
        },
        {
          name: `googlebot`,
          content: site.siteMetadata.googlebot,
        },
        {
          name: `bingbot`,
          content: site.siteMetadata.bingbot,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: `DreamClean`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: `sv_SE`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.image,
        },
        {
          property: `og:url`,
          content: "https://www.dreamclean.nu/",
        },
        {
          property: `article:modified_time`,
          content: "2020-03-23T08:13:27+00:00",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `format-detection`,
          content: "telephone=no",
        },
        {
          name: `google-site-verification`,
          content: "TXNQylqHi-iGdtECHoIWPC2TXTPns4afsBPULK6362E",
        },
      ].concat(meta)}
    />
  );
};
