import React from "react";
import Logo from "../../assets/images/icons/logo.svg";
// import { HiOutlineMail } from "react-icons/hi";
// import { MdPhoneIphone } from "react-icons/md";
// import { IoLocationSharp } from "react-icons/io5";
import { Link, navigate } from "gatsby";

const Footer = ({ chosenCity }) => {

	const handleFirstCityClick = () => {
		localStorage.setItem('city', 'jonkoping');
		navigate('/');
	}

	const handleSecondCityClick = () => {
		localStorage.setItem('city', 'linkoping');
		navigate('/linkoping/');
	}

	return (
		<footer class="bg-white" aria-labelledby="footerHeading">
			<h2 id="footerHeading" class="sr-only">
				Footer
			</h2>
			<div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
				<div class="xl:grid xl:grid-cols-3 xl:gap-8">
					<div class="space-y-8 xl:col-span-1">
						{/* <img
              class="h-10"
              src="https://tailwindui.com/img/logos/workflow-mark-gray-300.svg"
              alt="Company name"
            /> */}
						<Logo class="h-10 w-auto" />
						<p class="text-gray-500 text-base">
							Städfirma med visionen att skapa en enklare vardag genom att
							erbjuda högsta kvalitet av service till lågt pris.
						</p>
						<div class="flex space-x-6">
							<a href="#!" class="text-gray-400 hover:text-gray-500">
								<span class="sr-only">Facebook</span>
								<svg
									class="h-6 w-6"
									fill="currentColor"
									viewBox="0 0 24 24"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
										clip-rule="evenodd"
									/>
								</svg>
							</a>

							<a href="#!" class="text-gray-400 hover:text-gray-500">
								<span class="sr-only">Instagram</span>
								<svg
									class="h-6 w-6"
									fill="currentColor"
									viewBox="0 0 24 24"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
										clip-rule="evenodd"
									/>
								</svg>
							</a>
						</div>
					</div>
					<div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
						<div class="md:grid md:grid-cols-2 md:gap-8">
							<div>
								<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
									Tjänster
								</h3>
								<ul class="mt-4 space-y-4">
									<li>
										<Link
											to={chosenCity === "linkoping" ? "/linkoping/tjanster/hemstadning" : "/tjanster/hemstadning"}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Hemstädning
										</Link>
									</li>

									<li>
										<Link
											to={chosenCity === "linkoping" ? "/linkoping/tjanster/flyttstadning" : "/tjanster/flyttstadning"}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Flyttstädning
										</Link>
									</li>

									<li>
										<Link
											to={chosenCity === "linkoping" ? "/linkoping/tjanster/storstadning" : "/tjanster/storstadning"}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Storstädning
										</Link>
									</li>

									<li>
										<Link
											to={chosenCity === "linkoping" ? "/linkoping/tjanster/kontorsstadning" : "/tjanster/kontorsstadning"}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Kontorsstädning
										</Link>
									</li>
								</ul>
							</div>
							<div class="mt-12 md:mt-0">
								<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
									Här finns vi
								</h3>
								<ul class="mt-4 space-y-4">
									<li>
										<div
											onClick={handleFirstCityClick}
											aria-hidden="true"
											style={{ cursor: 'pointer' }}
											class="text-base text-gray-500 hover:text-gray-900 mb-4"
										>
											Jönköping
										</div>
										<div
											onClick={handleSecondCityClick}
											aria-hidden="true"
											style={{ cursor: 'pointer' }}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Linköping
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="md:grid md:grid-cols-2 md:gap-8">
							<div>
								<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
									Om oss
								</h3>
								<ul class="mt-4 space-y-4">
									<li>
										<Link
											to={chosenCity === "linkoping" ? "/linkoping/dreamclean-culture" : "/dreamclean-culture"}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Om oss
										</Link>
									</li>

									<li>
										<Link
											to={chosenCity === "linkoping" ? "/linkoping/integritets-och-cookiespolicy" : "/integritets-och-cookiespolicy"}
											class="text-base text-gray-500 hover:text-gray-900"
										>
											Integritetspolicy
										</Link>
									</li>
								</ul>
							</div>
							<div class="mt-12 md:mt-0">
								<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
									Kundsupport
								</h3>
								<ul class="mt-4 space-y-4">
									<li>
										<a
											href="tel:+4636141500"
											class="text-base text-gray-500 hover:text-gray-900 flex footer-link-wrap"
										>
											{/* <MdPhoneIphone size={23} style={{ marginRight: 6, marginTop: 4 }} /> */}
											{chosenCity === "linkoping" ? `013 - 15 16 00` : `036 - 14 15 00`}
										</a>
									</li>
									<li>
										<a
											href={`mailto:${chosenCity === "linkoping" ? 'linkoping@dreamclean.nu' : 'info@dreamclean.nu'}`}
											class="text-base text-gray-500 hover:text-gray-900 flex footer-link-wrap"
										>
											{/* <HiOutlineMail size={23} style={{ marginRight: 6, marginTop: 4 }} /> */}
											{chosenCity === "linkoping" ? 'linkoping@dreamclean.nu' : 'info@dreamclean.nu'}
										</a>
									</li>
									<li>
										<a
											href="mailto:info@dreamclean.nu"
											class="text-base text-gray-500 hover:text-gray-900 flex footer-link-wrap"
										>
											{/* <IoLocationSharp size={23} style={{ marginRight: 6, marginTop: 4 }} /> */}
											<p
												class="text-base text-gray-500 hover:text-gray-900"
												style={{ width: "80%" }}
											>
												{chosenCity === "linkoping" ? `Gamla Tanneforsvägen 90, 582 54 Linköping` : `Huskvarnavägen 62, 554 54 Jönköping`}
											</p>
										</a>
									</li>
									<li className="text-base text-gray-500 hover:text-gray-900 ">
										Kundservice har öppet måndag-fredag kl. 8:00-17:00
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-12 border-t border-gray-200 pt-8">
					<p class="text-base text-gray-400 xl:text-center">
						&copy; 2021 {chosenCity === "linkoping" ? `Dreamclean i Linköping AB | Org. nr. 559257-0617` : `Dreamclean Sweden AB | Org. nr. 559141-5707`}
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
