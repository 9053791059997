import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { Link } from "gatsby";
import { SERVICE_TYPES } from "../../enums/serviceTypes";
import Logo from "../../assets/images/icons/logo.svg";
import LogoMobile from "../../assets/images/icons/logo-white.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsChevronLeft } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { globalHistory } from "@reach/router";

const Navbar = (props) => {
	const [linksActive, setLinksActive] = useState(false);
	const [dropdownActive, setDropdownActive] = useState(false);
	// const [servicesLinksActive, setServicesLinksActive] = useState(false);

	console.log(props.serviceChosen)

	const toggleMenu = () => {
		setLinksActive((p) => !p);
	};

	const toggleDropdown = () => {
		setDropdownActive((p) => !p);
	};

	const scrollToBooking = () => {
		const elem = document.getElementById("booking");
		elem.scrollIntoView({ behavior: "smooth", block: "end" });
	};

	useEffect(() => {
		if (linksActive) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "scroll";
		}
	}, [linksActive]);

	return (
		<>
			<header
				// class="bg-white fixed w-full"
				className={"Navbar" + (props.serviceChosen ? " inBooking" : "")}
				style={{ zIndex: 20 }}
			>
				{props.ableToGoBack && props.finishBookingStep >= 3 && (
					<button
						className="back"
						onClick={() => {
							props.setFinishBookingStep((p) => p - 1);
							window.scrollTo(0, 0);
						}}
					>
						<BsChevronLeft />
					</button>
				)}
				<Link to={props.chosenCity === "linkoping" ? "/linkoping/" : "/"} className="logo-mobile-link">
					<LogoMobile
						className={
							"logo-mobile" +
							(props.ableToGoBack && props.finishBookingStep >= 3
								? " backable"
								: "")
						}
					/>
				</Link>
				<Link to={props.chosenCity === "linkoping" ? "/linkoping/" : "/"} className="logo-desktop-link">
					<Logo className="logo-desktop" />
				</Link>

				<div className="links">
					<a
						href="#!"
						// onMouseEnter={() => setServicesLinksActive(true)}
						// onMouseLeave={() => setServicesLinksActive(false)}
						aria-hidden="true"
						className="first-link"
					>
						Tjänster
						<div
							className={"services-desktop"}
							// onMouseEnter={() => setServicesLinksActive(true)}
							// onMouseLeave={() => setServicesLinksActive(false)}
							aria-hidden="true"
						>
							<div className="services-links">
								<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/hemstadning" : "/tjanster/hemstadning"}>{SERVICE_TYPES.Hemstädning}</Link>
								<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/flyttstadning" : "/tjanster/flyttstadning"}>
									{SERVICE_TYPES.Flyttstädning}
								</Link>
								<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/storstadning" : "/tjanster/storstadning"}>{SERVICE_TYPES.Storstädning}</Link>
								<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/kontorsstadning" : "/tjanster/kontorsstadning"}>
									{SERVICE_TYPES.Kontorsstädning}
								</Link>
							</div>
						</div>
					</a>
					<Link
						to={props.chosenCity === "linkoping" ? "/linkoping/har-finns-vi" : "/har-finns-vi"}
					>
						Här finns vi
					</Link>
					<Link
						to={props.chosenCity === "linkoping" ? "/linkoping/dreamclean-culture" : "/dreamclean-culture"}
					>
						Om oss
					</Link>
					<Link to="#!"
					// onMouseEnter={() => setServicesLinksActive(false)}
					>
						Jobba med oss
					</Link>
					{!props.bookingActive &&
						(globalHistory.location.pathname !== "/" ? (
							<Link
								to={props.chosenCity === "linkoping" ? "/linkoping/" : "/"}
								className="last-link"
							// onMouseEnter={() => setServicesLinksActive(false)}
							>
								Beställ städning
							</Link>
						) : (
							<a
								href="#!"
								onClick={scrollToBooking}
								className="last-link"
							// onMouseEnter={() => setServicesLinksActive(false)}
							>
								Beställ städning
							</a>
						))}
				</div>

				<div className="mobile-right">
					{!linksActive &&
						!props.serviceChosen &&
						(globalHistory.location.pathname !== "/" ? (
							<Link href={props.chosenCity === "linkoping" ? "/linkoping/" : "/"} className="last-link-mobile">Beställ städning</Link>
						) : (
							<a href="#!" onClick={scrollToBooking} className="last-link-mobile">
								Beställ städning
							</a>
						))}
					{linksActive ? (
						<div className="close-menu" onClick={toggleMenu} aria-hidden="true">
							<IoClose />
						</div>
					) : (
						!props.serviceChosen && (
							<div
								className="burger-menu"
								onClick={toggleMenu}
								aria-hidden="true"
							>
								<div className="line" />
								<div className="line" />
								<div className="line" />
							</div>
						)
					)}
				</div>
			</header>
			<div className={"mobile-links" + (linksActive ? " active" : "")}>
				<div className="top-links">
					<a
						href="#!"
						className={"dropdown" + (dropdownActive ? " active" : "")}
						onClick={toggleDropdown}
					>
						Tjänster {dropdownActive ? <FaChevronUp /> : <FaChevronDown />}
					</a>
					<div className={"dropdown-links" + (dropdownActive ? " active" : "")}>
						<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/hemstadning" : "/tjanster/hemstadning"}>Hemstädning</Link>
						<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/flyttstadning" : "/tjanster/flyttstadning"}>Flyttstädning</Link>
						<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/storstadning" : "/tjanster/storstadning"}>Storstädning</Link>
						<Link to={props.chosenCity === "linkoping" ? "/linkoping/tjanster/kontorsstadning" : "/tjanster/kontorsstadning"}>Kontorsstädning</Link>
					</div>
					<Link
						to={props.chosenCity === "linkoping" ? "/linkoping/har-finns-vi" : "/har-finns-vi"}
					>
						Här finns vi
					</Link>
					<Link to={props.chosenCity === "linkoping" ? "/linkoping/dreamclean-culture" : "/dreamclean-culture"}>Om oss</Link>
					<a href="#!">Jobba med oss</a>
				</div>
				{!props.serviceChosen &&
					(globalHistory.location.pathname !== "/" ? (
						<div
							className="bottom-btn"
							// onClick={() => setLinksActive(false)}
							aria-hidden="true"
						>
							<Link to={props.chosenCity === "linkoping" ? "/linkoping/" : "/"}>Beställ städning</Link>
						</div>
					) : (
						<div
							className="bottom-btn"
							onClick={() => {
								scrollToBooking();
								setLinksActive(false);
							}}
							aria-hidden="true"
						>
							<a href="#!">Beställ städning</a>
						</div>
					))}
			</div>

		</>
	);
};

export default Navbar;
