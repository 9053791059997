import React, { useEffect, useState } from "react";
import "./Layout.scss";
import "../../styles/globals.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Loader from '../Loader/Loader';
import ChooseCity from "../../components/ChooseCity/ChooseCity";

const Layout = (props) => {

	const [chosenCity, setChosenCity] = useState();
	const [loaded, setLoaded] = useState();

	useEffect(() => {
		const city = localStorage.getItem('city');

		setChosenCity(city);
		setLoaded(true);
	}, []);

	return (
		<>
			{!loaded && <Loader />}
			{!chosenCity && <ChooseCity />}
			<div className={"Layout" + (props.bookingActive ? ' book-active' : '')}>
				<Navbar
					bookingActive={props.bookingActive}
					serviceChosen={props.serviceChosen}
					ableToGoBack={props.ableToGoBack}
					setFinishBookingStep={props.setFinishBookingStep}
					finishBookingStep={props.finishBookingStep}
					chosenCity={chosenCity}
				/>
				{props.children}
				{!props.bookingActive && <Footer chosenCity={chosenCity} />}
			</div>
		</>
	);
};

export default Layout;
