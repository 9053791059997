import React, { useEffect } from 'react';
import './ChooseCity.scss';
import { navigate } from "gatsby";

const ChooseCity = () => {

	useEffect(() => {
		const scripts = document.getElementById("scripts");

		const script1 = document.createElement("script");
		script1.src = "https://widget.reco.se/yearsInRowBadge/4025944.js";
		script1.async = true;

		const script2 = document.createElement("script");
		script2.src = "https://widget.reco.se/badge/2021/4025944.js";
		script2.async = true;

		const script3 = document.createElement("script");
		script3.src = "https://widget.reco.se/badge/2020/4025944.js";
		script3.async = true;

		const script4 = document.createElement("script");
		script4.src = "https://widget.reco.se/badge/2019/4025944.js";
		script4.async = true;

		const script5 = document.createElement("script");
		script5.src = "https://widget.reco.se/badge/2018/4025944.js";
		script5.async = true;

		scripts.appendChild(script1);
		scripts.appendChild(script2);
		scripts.appendChild(script3);
		scripts.appendChild(script4);
		scripts.appendChild(script5);
	}, []);

	const handleFirstCityClick = () => {
		localStorage.setItem('city', 'jonkoping');
		navigate('/');
		window.location.href = '/';
	}

	const handleSecondCityClick = () => {
		localStorage.setItem('city', 'linkoping');
		navigate('/linkoping/');
	}

	return (
		<div className="ChooseCity">
			<div className="choose-content">
				<h1>Välj stad</h1>
				<p>Välj den stad som är närmast dig för att komma till ditt lokala kontor:</p>
				<div className="cities">
					<button className="city" onClick={handleFirstCityClick}>
						Jönköping
					</button>
					<button className="city" onClick={handleSecondCityClick}>
						Linköping
					</button>
				</div>
				<div
					className="certificats"
					id="scripts"
				>
					<div
						id="reco--badge-yearsInRowBadge"
						className="badge"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2021"
						className="badge"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2020"
						className="badge"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2019"
						className="badge"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2018"
						className="badge"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default ChooseCity;